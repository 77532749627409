<template>
  <div class="content-container">
    <div class="auth-form">
      <!--    <h2 class="page-header__title">Caena Admin Login</h2>-->
      <!--    <div class="page-header__sub mb-2">-->
      <!--      <p class="">Login to manage affairs!</p>-->
      <!--    </div>-->
      <div class="row">
        <div class="col-xs-12 text-center sign-in-header">
          <h2>Sign in to your account</h2>
        </div>
        <div class="col-xs-12">
          <div class="form__group">
            <input
              type="email"
              name="email"
              class="form__input"
              placeholder="Email address"
              id="email"
              v-model="email"
            />
          </div>

          <div class="form__group">
            <input
              type="password"
              name="password"
              class="form__input"
              placeholder="Password"
              id="password"
              v-model="password"
            />
          </div>
          <div class="form__group--row mb-2">
            <input type="checkbox" class="form__checkbox" />
            <label for="password" class="form__label">Keep me logged in</label>
          </div>
          <button @click="loginAdmin" class="auth-button">
            <span class="mr-1" v-if="isLoading"
              ><i class="fa fa-spinner fa-spin"></i
            ></span>
            <span>Sign in</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      isLoading: false,
      email: "",
      password: "",
    };
  },
  methods: {
    loginAdmin() {
      // pelide4773@ovooovo.com
      const loading = this.$vs.loading();
      this.isLoading = true;
      if (this.email === "" || this.password === "") {
        this.$toast("Please provide a valid email and password", {
          position: "top-center",
          type: "error",
        });
        this.isLoading = false;
        loading.close();
        return;
      }

      this.$store
        .dispatch("retrieveToken", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          console.log(res);
          this.$toast("Login successful", {
            position: "top-center",
            type: "success",
          });
          this.isLoading = false;
          loading.close();
          this.$router.push({ name: "dashboard" });
        })
        .catch((error) => {
          let errMessage;
          if (typeof error !== "string") {
            errMessage = "An error occurred while trying to log user in";
            console.log(error);
          } else {
            errMessage = error;
          }
          this.$toast(errMessage, {
            type: "error",
            position: "top-center",
          });
          this.isLoading = false;
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  display: flex;
  justify-content: center;
}
.auth-form {
  margin-top: 4rem;
  width: 40rem;
  background-color: #ffffff;
  padding: 3rem;
}

button.auth-button {
  border-radius: 4px;
  border: 1px solid #124f4e;
  font-family: inherit;
  font-weight: 500;
  display: block;
  width: 100%;
  background-color: #124f4e;
  font-size: 1.2rem;
  padding: 1.3rem;
  color: #ffffff;
}

.sign-in-header {
  margin-bottom: 4rem;
  & > h2 {
    font-size: 1.7rem;
  }
}

input {
  background-color: #f5f5f5;
  border: 0.5px solid #707070;
  padding: 1.3rem;
}
.form__group--row {
  & > label {
    display: inline-block;
  }

  & > input {
    margin-right: 1rem;
  }
}
</style>
